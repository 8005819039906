<template>
  <div>
    <SideBar />
    <CWrapper>
      <TheHeader ref="header" />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view> </router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
    <base-tost-danger></base-tost-danger>
    <base-tost-success></base-tost-success>
    <call-back-modal ref="btnToggle"></call-back-modal>
  </div>
</template>

<script>
import TheHeader from '@/components/header/TheHeader.vue';
import SideBar from '@/components/SideBar.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },

  mounted() {
    // Скрытие шапки сайта при скролле
    if (window.innerWidth < 992) {
      const header = this.$refs.header.$el;
      header.style.position = 'fixed';

      // Получает высоту экрана
      const screenHeight = window.innerHeight;

      // Получает высоту страницы с учетом скролла
      const pageHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );

      // Получает максимальную высоту скролла
      const maxScroll = pageHeight - screenHeight;

      // Получает предыдущую позицию скролла
      let prevScrollpos = window.pageYOffset;

      window.onscroll = () => {
        let headerHeight = 106;

        if (window.innerWidth < 576 || (window.innerWidth > 576 && window.innerHeight < 576)) {
          headerHeight = 68;
        }
        const currentScrollPos = window.pageYOffset;

        // Показывает меню, если скроллится вверх страница и скрывает, если вниз
        if (prevScrollpos < currentScrollPos && currentScrollPos > headerHeight) {
          header.style.top = '-4.25rem';
        } else {
          header.style.top = '0';
        }
        prevScrollpos = currentScrollPos;

        // Если доскроллили до конца страницы показывает меню
        if (maxScroll === currentScrollPos) {
          header.style.top = '0';
        }
      };
    }
  },

  computed: mapGetters({ getUserByStore: 'loginStore/getUser' }),

  methods: {
    ...mapActions({ selectUser: 'loginStore/selectUser' })
  },

  components: {
    TheHeader,
    SideBar,
    BaseTostSuccess: () => import('@/components/shared/tosts/BaseTostSuccess.vue'),
    BaseTostDanger: () => import('@/components/shared/tosts/BaseTostDanger.vue'),
    CallBackModal: () => import('@/components/CallBackModal.vue')
  },

  async beforeMount() {
    if (!this.getUserByStore.Id) {
      await this.selectUser();
    }
  }
};
</script>

<style scoped>
.main {
  padding-left: 17.5rem;
  transition: 0.5s;
}

._in-close-menu {
  padding-left: 3.75rem;
}

.c-main {
  background: #ecf0f3;
}
</style>
