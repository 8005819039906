<template>
  <CHeader fixed withSubheader light class="dflex space-between-v" ref="header">
    <div :class="$style.headerTopWrap">
      <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('sideBarStore/toggleSidebarDesktop')"
      />
      <div :class="$style.contentWrap">
        <header-contacts :class="$style.headerContacts"></header-contacts>
        <CHeaderBrand class="mx-md-auto d-lg-none" :class="$style.logoWrap" to="/">
          <CIcon :class="$style.logo" :src="dialog" alt="логотип onlinelogic" />
        </CHeaderBrand>
        <CHeaderNav class="sm-mx-1 md-mr-3">
          <CHeaderNavItem class="dflex valign-center d-none d-sm-flex">
            <CHeaderNavLink style="position: relative" toggle>
              <div>
                <b-dropdown
                  size="lg"
                  variant="link"
                  class="notifications"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <b-dropdown-header :class="$style.notificationsHeader">
                    У Вас {{ countNotifications }} {{ notificationHeaderText }}
                  </b-dropdown-header>
                  <template #button-content>
                    <CIcon :content="iconsSet.cilBell" class="notifications-bell" />
                  </template>
                  <CDropdownItem
                    v-for="notification in notificationsList"
                    :key="notification"
                    tag="div"
                    :class="[
                      { [$style.notificationsItemWrap]: isHugeNotificationLength },
                      $style.notificationsItem
                    ]"
                    class="text-center"
                    color="light"
                  >
                    {{ notification }}
                  </CDropdownItem>
                </b-dropdown>
              </div>
              <CBadge v-show="isNotifications" color="info" class="mfs-auto">
                {{ countNotifications }}
              </CBadge>
            </CHeaderNavLink>
          </CHeaderNavItem>
          <CDropdown
            inNav
            :toggler-text="getUser.DisplayName"
            placement="bottom-end"
            add-menu-classes="pt-0"
            class="mr-2 c-header-nav-items account-button_wrap"
          >
            <CDropdownHeader tag="div" class="text-center" color="light">
              <strong>{{ getUser.DisplayName }}</strong>
            </CDropdownHeader>
            <CDropdownItem @click="goToSettings">
              <CIcon name="cil-settings" /> Настройки профиля
            </CDropdownItem>
            <CDropdownItem @click="logout">
              <CIcon name="cil-lock-locked" />
              Выход
            </CDropdownItem>
          </CDropdown>
        </CHeaderNav>
      </div>
    </div>
    <CSubheader>
      <sub-header></sub-header>
    </CSubheader>
  </CHeader>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SubHeader from '@/components/header/SubHeader.vue';
import HeaderContacts from '@/components/header/HeaderContacts.vue';
import dialog from '@/assets/login/logo_ru.png';
import { iconsSet } from '@/assets/icons/icons';

export default {
  data() {
    return {
      dialog,
      iconsSet
    };
  },

  components: {
    SubHeader,
    HeaderContacts
  },

  computed: {
    ...mapGetters({ getUser: 'loginStore/getUser' }),
    ...mapState({ notificationsList: (state) => state.notifications.module.notificationsList }),

    isNotifications() {
      return this.notificationsList.length > 0;
    },

    countNotifications() {
      return this.notificationsList.length;
    },

    notificationHeaderText() {
      const ia = [2, 3, 4];
      if (this.countNotifications === 0 || this.countNotifications > 4) return 'уведомлений';
      if (ia.includes(this.countNotifications)) return 'уведомления';
      return 'уведомление';
    },

    isHugeNotificationLength() {
      if (this.notificationsList.length) return this.notificationsList.some((el) => el.length > 40);
      return false;
    }
  },

  methods: {
    ...mapActions({ logoutAction: 'loginStore/logout' }),

    async logout() {
      await this.logoutAction();
      this.$router.push('/login');
    },

    goToSettings() {
      this.$router.push({ path: '/settings-profile' });
    }
  }
};
</script>

<style lang="scss" scoped module>
.headerTopWrap {
  display: flex;
  flex-wrap: nowrap;
  min-height: 67px;
  justify-content: space-between;
  align-items: center;
}

.contentWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 1.5rem;
}

.headerContacts {
  display: none;
}

.logoWrap {
  min-height: auto;
}

.logo {
  height: 100%;
  max-height: 3rem;
}

.notificationsHeader {
  background: $backgroundGrey;
}

.notificationsItem:not(:last-child) {
  border-bottom: 1px solid $lightGrey;
}

.notificationsItemWrap {
  display: block;
  pointer-events: none;
  white-space: break-spaces;
}

@media (min-width: 768px) {
  .contentWrap {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .headerContacts {
    display: flex;
    padding-left: 1.5rem;
  }
}

@media (min-width: 992px) {
  .headerContacts {
    flex-grow: 1;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.dropdown-header {
  white-space: normal;
}

.account-button_wrap {
  width: 100%;
  max-width: 8.125rem;

  & > a {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.notifications .dropdown-menu {
  transform: translate3d(-9.375rem, 2.8125rem, 0px) !important;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 20rem;
}

.notifications-bell {
  color: $mainBlack;
}

@media (min-width: 576px) {
  .account-button_wrap {
    max-width: 8rem;
  }
}

@media (min-width: 768px) {
  .account-button_wrap {
    max-width: 7.5rem;
  }
}

@media (min-width: 992px) {
  .account-button_wrap {
    max-width: 12.5rem;
  }
}
</style>
