// eslint-disable-next-line import/prefer-default-export
const menu = [
  {
    title: 'Обработка звонков',
    dropdown: [
      { name: 'Распределение входящего вызова', url: '/work-in-progress' },
      { name: 'Изменение голосового приветствия', url: '/work-in-progress' },
      { name: 'Персональная мелодия звонка', url: '/work-in-progress' },
      { name: 'Фильтр нежелательных звонков', url: '/work-in-progress' },
      { name: 'Уведомление о времени ожидания абонента на линии', url: '/work-in-progress' }
    ],
    icon: 'cilCog'
  },
  {
    title: 'Финансы',
    dropdown: [
      { name: 'Документы', url: '/finance-documents' },
      { name: 'Расходы на связь', url: '/work-in-progress' },
      { name: 'Реквизиты', url: '/org-details' },
      { name: 'Пополнить счет', url: '/work-in-progress' }
    ],
    icon: 'cilBalanceScale'
  },
  {
    title: 'Аналитика',
    dropdown: [
      { name: 'Описание', url: '/reports-description' },
      { name: 'Анализ звонков', url: '/calls-analysis-reports/service-metrics' },
      { name: 'Оценка эффективности рекламы', url: '/reports?name=OnLo_Marketing&service=Calltracking' }
    ],
    icon: 'cilChartPie'
  }
];

export default {
  menu
};
