// в дальнейшем меню будет приходить с сервака, для каждой роли свое.
export default [{
  _name: 'CSidebarNav',
  _children: [{
    _name: 'CSidebarNavDropdown',
    name: 'Виртуальная АТС',
    icon: 'cilBuilding',
    items: [
      {
        name: 'Состояние услуги',
        to: '/Vpbx/common-info'
      },
      {
        name: 'Записи разговоров',
        to: '/Vpbx/records'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Конференц-связь',
        to: '/services/conference-call'
      },
      {
        name: 'История вызовов',
        to: '/reports/calls'
      },
      {
        name: 'Отчеты',
        to: '/reports?name=Виртуальная_АТС&service=HostedPBX'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Интеграции',
    icon: 'cilChartPie',
    items: [
      {
        name: 'Мои интеграции',
        to: '/cri-my-connected'
      },
      {
        name: 'Подключить',
        to: '/cri-connect'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'OnLo Dialog',
    icon: 'cilVoiceOverRecord',
    items: [
      {
        _name: 'CSidebarNavItem',
        name: 'Голосовая рассылка',
        to: '/auto-dialer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Голосовой бот',
        to: '/voice-bot'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Преобразование текста в речь',
        to: '/text-to-speach'
      },
      {
        name: 'Отчеты',
        to: '/reports?name=OnLo_Dialog&service=AutoDialler'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'OnLo Marketing',
    icon: 'cilFilter',
    items: [
      {
        name: 'Колл трекинг',
        to: '/services/call-tracking'
      },
      {
        name: 'Звонок с сайта',
        to: '/services/callback'
      },
      {
        name: 'Отчеты',
        to: '/reports?name=OnLo_Marketing&service=Calltracking'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Другие продукты OnLo',
    icon: 'cil-puzzle',
    items: [
      {
        name: 'OnLo Phone',
        to: '/onLo-phone'
      },
      {
        name: 'Линия приоритетного обслуживания ',
        to: '/work-in-progress'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Заявки пользователя',
    icon: 'cil-puzzle',
    items: [
      {
        name: 'Создать заявку',
        to: '/create-statement'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Настройки',
    icon: 'cil-settings',
    items: [
      {
        name: 'Настройки пользователей',
        to: '/settings-all-profile'
      }
    ]
  }]
}];

// {
//   name: 'Межгород',
//   to: '/services/intercity',
// },
// {
//   name: 'Конференц-связь',
//   to: '/services/conference-call',
// },
