<template>
  <div :class="$style.wrap" class="dflex valign-center">
    <div>
      <div :class="$style.contactsWrap">
        <a href="tel:+7395270-70-55" class="dflex valign-center">
          <PhoneSvg :class="$style.phoneIcon"></PhoneSvg>
          <span>+7 (495) 44-96-000</span>
        </a>
        <a :class="$style.mail" href="mailto:info@onlinelogic.ru">info@onlinelogic.ru</a>
      </div>
    </div>
    <div :class="$style.callBack" @click="openModal"></div>
  </div>
</template>

<script>
import PhoneSvg from '@/components/shared/icons_and_images/PhoneSvg.vue';

export default {
  data() {
    return {
      modalName: 'callback'
    };
  },

  components: {
    PhoneSvg
  },

  methods: {

    openModal() {
      this.$root.$emit('bv::toggle::modal', 'callback-modal', '#btnToggle');
    }
  }
};
</script>

<style lang="scss" module>
.contactsWrap {
  text-align: right;
  letter-spacing: 0.6px;

  & *:hover {
    color: $red;
  }
}

.phoneIcon {
  width: 0.875rem;
  margin-right: 0.5rem;

  svg path {
    fill: black;
  }
}

.mail {
  font-size: 0.875rem;
}

.callBack {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  background-color: $red;
  background-image: url('../../assets/callback.png');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
}

@media (min-width: 992px) {
  .callBack {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
