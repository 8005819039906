<template>
  <CSidebar
    fixed
    :show="getClose"
    :minimize="minimize"
    size="lg"
    color-scheme="light"
    dropdownMode="openActive"
    :class="$style.sideBarWrap"
    @update:show="() => $store.commit('sideBarStore/close')"
  >
    <CSidebarBrand href="https://onlinelogic.ru/" target="_blanc" :class="$style.logoBackground" class="py-3">
      <CIcon
        class="c-sidebar-brand-full"
        :src="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 168 76"
      />
      <!-- для логотипа при сворачивание меню -->
      <CIcon
        class="c-sidebar-brand-minimized"
        :src="miniLogo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRenderFunction
      :class="$style.servicesList"
      class="h6"
      flat
      :content-to-render="$options.nav"
    />
    <header-contacts :class="$style.contacts"></header-contacts>
    <CSidebarMinimizer class="d-md-down-none" @click.native="smalMenu" />
  </CSidebar>
</template>

<script>
import HeaderContacts from '@/components/header/HeaderContacts.vue';
import { mapGetters } from 'vuex';
import logo from '@/assets/login/logo_ru.png';
import miniLogo from '@/assets/small-logo.svg';
import nav from '@/utils/_nav';

export default {
  nav,
  data() {
    return {
      minimize: false,
      // ic: '@/assets/hello.svg',
      miniLogo,
      logo
    };
  },

  components: {
    HeaderContacts
  },

  computed: mapGetters({ getClose: 'sideBarStore/getClose' }),

  methods: {
    smalMenu() {
      this.minimize = !this.minimize;
    }
  }
};
</script>

<style lang="scss" scoped module>
// .c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
// .c-sidebar .c-sidebar-nav-link:hover:hover {
//   background: $red;
// }
// .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover {
//   color: $white;
// }

.logoBackground{
  background: $backgroundGrey!important;
}

.sideBarWrap {
  max-width: 90%;
}

.servicesList {
  margin-bottom: 0;
}

.contacts {
  padding: 0.5rem 1rem 1rem 1rem;
  align-self: center;
  background: #cfd4d8;
  width: 100%;
  justify-content: center;
}

@media (min-width: 992px) {
  .contacts {
    display: none;
  }

  .sideBarWrap {
    max-width: initial;
  }
}
</style>

<style lang="scss">
.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  padding: 0.625rem 1rem;
}

.c-show .c-sidebar-nav-dropdown-items {
  background: $white;
}

// .c-show > .c-sidebar-nav-dropdown-toggle,
// .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
//   background: $white;
// }

// .c-show > .c-sidebar-nav-dropdown-toggle {
//   box-shadow: 0 10px 14px -7px #bcbec3;
// }

// .c-show {
//   border-bottom: 1px solid $lightGrey;
// }
</style>
