import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=08c867c1&scoped=true&"
import script from "./SideBar.vue?vue&type=script&lang=js&"
export * from "./SideBar.vue?vue&type=script&lang=js&"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=08c867c1&lang=scss&scoped=true&module=true&"
import style1 from "./SideBar.vue?vue&type=style&index=1&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "08c867c1",
  null
  
)

export default component.exports