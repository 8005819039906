<template>
  <div :class="$style.wrap" class="subheader_wrap d-flex">
    <CNavItem href="/" class="valign-center d-none d-lg-flex">Главная</CNavItem>
    <CDropdown
      v-for="menuItem in subMenu.menu"
      :key="menuItem.title"
      :togglerText="menuItem.title"
      class="d-none d-lg-flex"
    >
      <CDropdownItem
        v-for="item in menuItem.dropdown"
        :key="item.name"
        :to="item.url"
        :class="$style.link"
        text-wrap
        class="mx-auto"
        white-space
      >
        {{ item.name }}
      </CDropdownItem>
    </CDropdown>
    <div :class="$style.subheaderMobile" class="dflex d-lg-none">
      <CNavItem href="/" class="valign-center dflex d-lg-none">
        <CIcon :content="iconsSet.cilHome" size="xl" />
      </CNavItem>
      <b-dropdown
        v-for="menuItem in subMenu.menu"
        :key="menuItem.title"
        class="d-flex d-lg-none"
        size="lg"
        toggle-class="text-decoration-none subheader-mobile"
        no-caret
      >
        <template #button-content>
          <CIcon :content="iconsSet[menuItem.icon]" size="xl" />
        </template>
        <CDropdownItem
          v-for="item in menuItem.dropdown"
          :key="item.name"
          :to="item.url"
          :class="$style.link"
          text-wrap
          class="mx-auto"
          white-space
        >
          {{ item.name }}
        </CDropdownItem>
      </b-dropdown>
      <CToggler
        in-header
        :class="$style.hamburger"
        class="sm-ml-3"
        @click="$store.commit('sideBarStore/toggleSidebarMobile')"
      />
    </div>
  </div>
</template>

<script>
import subMenu from '@/utils/_subheader_nav';
import { iconsSet } from '@/assets/icons/icons';

export default {
  data() {
    return {
      subMenu,
      iconsSet
    };
  }
};
</script>

<style lang="scss" module>
.wrap {
  width: 100%;
  line-height: 1.6;
  justify-content: flex-start;
  background: $white;
  box-shadow: 0 3px 5px -2px $lightGrey;

  & * {
    white-space: initial;
  }
}

.subheaderMobile {
  width: 100%;
  justify-content: space-around;
}

.link {
  text-decoration: none;
}

@media (min-width: 576px) {
  .wrap {
    & * {
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss">
.subheader-mobile {
  background: none;
  border: none;
}

.subheader_wrap li:hover,
.subheader_wrap li:active,
.subheader_wrap li:focus,
.subheader_wrap .dropdown .btn:hover,
.subheader_wrap .dropdown .btn:active,
.subheader_wrap .dropdown .btn:focus,
.subheader_wrap .dropdown:hover,
.subheader_wrap .dropdown:active,
.subheader_wrap .dropdown:focus {
  background: $transparentGrey;
}

.subheader_wrap .dropdown .btn {
  border-radius: 0;
  height: 100%;
}

.subheader_wrap > .dropdown > .btn:focus {
  box-shadow: none;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $red;
  color: $white;
}

.dropdown-menu .dropdown-item:hover {
  transition: background 0.3s, color 0.3s;
}

.dropdown-menu {
  box-shadow: 0 3px 14px $lightGrey;
}

@media (max-width: 992px) {
  .c-subheader {
    position: fixed;
    bottom: 0;
  }
}
</style>
